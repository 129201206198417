import React from 'react';
import Layout from '../components/layout';
import Box, { BoxTitle } from '../elements/box';
import Card, { CardContent, CardImage } from '../elements/card';

const Reflexions = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="Réflexions" />
      <div className="columns">
        <div className="column">
          <Card>
            <CardImage src="main4" alt="main" size="is-4by3" /> 
          </Card>
        </div>
        <div className="column">
          <Card>
            <CardImage src="main5" alt="main" size="is-4by3" /> 
          </Card>
        </div>
        <div className="column">
          <Card>
            <CardImage src="main6" alt="main" size="is-4by3" /> 
          </Card>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
                <p>J’ai appris que l’amour peut arriver par surprise ou mourir en une nuit.</p>
                <p>Que de grands amis peuvent devenir de parfaits inconnus, et qu’au contraire, un inconnu peut devenir un ami 
                  pour la vie.</p>
                <p>Que le « jamais plus » n’arrive jamais et que » pour toujours » a une fin .</p>
                <p>Que celui qui veut, peut et y arrive. Que celui qui prend des risques ne perd jamais rien, et que celui qui ne 
                  risque rien ne gagne jamais rien.</p>
                <p>Que si on veut voir quelqu’un, il faut aller le chercher, car après c’est trop tard .</p>
                <p>Qu’avoir mal est inévitable, mais que souffrir est en option, et surtout, j’ai appris que nier les choses les 
                  plus évidentes ne sert absolument a rien.</p>
                <p><b>Elif Shafak</b></p>
              </em>
            </CardContent>
          </Card>
        </div>
        <div className="column">
          <Card height="">
            <CardImage src="reflexion" alt="reflexion" size="is-3by5" />
          </Card>
        </div>
        <div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
                <p>Le comment est concret, le pourquoi est évasif et souvent ne mène nulle part. Beaucoup de questions ou de situations 
                  demeurent inexplicables sous cet angle là.</p>
                <p>Si on se penche sur le comment, des éléments concrets apparaîtront. Le comment se base sur les faits, le pourquoi, sur 
                  l’interprétation. Aujourd’hui, on sait de manière de plus en plus précise comment l’univers s’est formé.</p>
                <p>En revanche, on ne sait toujours pas pourquoi...Le comment relève de l’exactitude. L’exactitude est universelle.</p>
                <p>Le pourquoi se préoccupe de la vérité. Qu’y a-t-il de plus personnel et individuel que la vérité ? Elle porte 
                  une robe différente pour chacun d’entre nous. Et la vérité de l’un sera le mensonge de l’autre.</p>
                <p><b>Hugo Horiot</b></p>
              </em>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
                <p>Notre peur la plus profonde n’est pas que nous ne soyons pas à la hauteur.<br />
                  Notre peur la plus profonde est que nous sommes puissants au-delà de toute limite.<br />
                  C’est notre propre lumière et non pas notre obscurité qui nous effraie le plus.<br />
                  Nous nous posons la question :<br />
                  « qui suis-je, moi, pour être brillant, radieux, talentueux, merveilleux ? »</p>
                  En fait, qui êtes-vous pour ne pas l’être ?<br />
                  Vous êtes un enfant de Dieu.<br />
                  <p>Vous restreindre, vivre petit ne rend pas service au monde.<br />
                  L’illumination n’est pas de vous rétrécir pour éviter d’insécuriser les autres.<br />
                  Nous sommes nés pour rendre manifeste la gloire de Dieu qui est en nous.<br />
                  Elle ne se trouve pas seulement chez quelques élus : elle est en chacun de nous et au fur et à mesure que nous 
                  laissons briller notre propre lumière, nous donnons inconsciemment aux autres la permission de faire de même.<br />
                  En nous libérant de notre propre peur, notre présence libère automatiquement les autres.<br /></p>
              <p>Discours de <b>Nelson Mandela</b>, texte de <b>Marianne Williamson</b></p>
              </em>
            </CardContent>
          </Card>
        </div><div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
                <p>Vos enfants ne sont pas vos enfants</p>

                <p>Vos enfants ne sont pas vos enfants.<br />Ils sont les fils et les filles de l’appel de la Vie à elle-même,<br />
                  Ils viennent à travers vous mais non de vous.<br />Et bien qu’ils soient avec vous, ils ne vous appartiennent pas.</p>

                <p>Vous pouvez leur donner votre amour mais non point vos pensées,<br />Car ils ont leurs propres pensées.</p>

                <p>Vous pouvez accueillir leurs corps mais pas leurs âmes,<br />Car leurs âmes habitent la maison de demain, que 
                  vous ne pouvez visiter, pas même dans vos rêves.</p>

                <p>Vous pouvez vous efforcer d’être comme eux,<br />mais ne tentez pas de les faire comme vous.<br />Car la vie ne 
                  va pas en arrière, ni ne s’attarde avec hier.</p>

                <p>Vous êtes les arcs par qui vos enfants, comme des flèches vivantes, sont projetés.<br />L’Archer voit le but sur 
                  le chemin de l’infini, et Il vous tend de Sa puissance pour que Ses flèches puissent voler vite et loin.</p>

                <p>Que votre tension par la main de l’Archer soit pour la joie;<br />Car de même qu’Il aime la flèche qui vole, Il 
                  aime l’arc qui est stable.</p>
                  <br />
                <p><b>Khalil Gibran, Le Prophète</b></p>
              </em>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
              <p>Rire, c'est risquer de paraître fou.<br />
                  Pleurer, c'est risquer de paraître fragile.<br />
                  Aller vers quelqu'un, c'est risquer de s'engager.<br />
                  Exposer ses sentiments, c'est risquer d'exposer son moi profond.<br />
                  Présenter ses idées, ses rêves aux autres, c'est risquer de les perdre.<br />
                  Aimer, c'est risquer de ne pas être aimé en retour.<br />
                  Vivre, c'est risquer de mourir.<br />
                  Espérer, c'est risquer de désespérer.<br />
                  Essayer, c'est risquer d'échouer.<br /></p>

                  <p>Mais, il faut prendre des risques, car le plus grand danger dans la vie c'est de ne rien risquer.<br />
                  Celui qui ne risque rien ne fait rien, n'a rien, n'est rien.<br />
                  Il peut éviter la souffrance mais il n'apprend rien, ne ressent rien, ne peut ni changer ni se développer, ne peut ni aimer ni vivre.<br />
                  Enchaîné par sa certitude, il devient esclave, il trahit sa liberté.<br />
                  Seuls ceux qui risquent d'aimer trouvent le chemin de la liberté.</p>
              </em>
            </CardContent>
          </Card>
        </div><div className="column">
          <Card>
            <CardContent>
              <em className="content justified-content">
                <p>Les mots s’enracinent dans le cœur et au plus profond de l’âme, quand ils sont étayés d’actions incarnées et 
                  réitérées.<br />Sans cette continuité, les paroles restent des notes éphémères qui s’habillent de doute et 
                  s’évaporent sans laisser de réelle empreinte.</p>
                <p>Être, c’est dire et incarner ses mots par ses actions en ayant résolu ses conflits internes, ceux qui colorent 
                  de paradoxes notre présence au monde en laissant le goût amer de l’insécurité dans le contact.</p>
                <p><b>Andiva</b> - 08/02/2020</p>
              </em>
            </CardContent>
              <div className="columns">
                <div className="column"></div>
                  <div className="column is-narrow">
                    <CardImage src="zebre" alt="zebre" size="is-128x128" rounded={true} />
                  </div>
                <div className="column"></div>
              </div>
          </Card>
        </div>
      </div>
    </Box>
  </Layout>
)

export default Reflexions;